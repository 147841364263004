import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Header from "../components/Header"

import '../styles/index.page.css'
import LightboxImage from "../components/LightboxImage";
import affiche from '../data/affiche'
import {Col, Grid, Row} from "react-flexbox-grid";

class SiteIndex extends React.Component {
  render() {
    const siteTitle = get(this,'props.data.site.siteMetadata.title');
    const siteDescription = get(this,'props.data.site.siteMetadata.description');

    return (
      <Layout>
          <Helmet
              htmlAttributes={{ lang: 'fr' }}
              meta={[{ name: 'description', content: siteDescription }]}
              title={siteTitle}
          />
        <Header />
        <div className={'mainInfos'}>

            <Grid fluid>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} key={'indexAffiche'}>
                    {affiche.map(a => (
                        <LightboxImage
                            src={a.image}
                            title={a.date}
                            author={a.headline}
                            key={'affiche'}
                        />
                    ))}
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} key={'indexDescription'}>
                      <p>
                        L’artiste existe, la palette à la main, et se soumet aux exigences techniques et picturales imposées par les règles de l'art. Il fait ce qu'il peut pendant des années de travail obstiné, patient, acharné, la même exigence reconduite toujours. Il doit appréhender les techniques afin qu'elles ne soient plus une entrave, car la maîtrise affirmera sa liberté en toute humilité. L'artiste sait qu'entre toutes ses œuvres celle qui lui ressemble et le révèle le mieux a été réalisée, en outre, dans la solitude.
                        <br/><br/>
                        On constate aujourd'hui que le seul critère admis pour évaluer la qualité d'une œuvre n'est pas son esthétisme, fruit d'un travail maîtrisé, mais bien la " cote " de l'artiste qui est le fruit des interventions et manipulations de galeristes et de marchands dont le seul but est de rentabiliser à outrance leurs opérations publicitaires.
                        <br/><br/>
                        Si depuis quelques années, on doit admettre que la finance détient le monopole du pouvoir, il faut saluer l'efficacité avec laquelle l'art la sert. De la décadence de l'art naît le vide à l'exemple du peintre italien Salvatore Garau qui a "créé " une sculpture invisible vendue 15000 Euros, en expliquant que le vide est un espace rempli d'énergie et même si on le vide et qu'il ne reste plus rien, selon le principe d'incertitude d’Heisenberg, le vide a un poids.
                        <br/><br/>
                        Heureusement, il existe en France une volonté de politique culturelle qui permet aux artistes non professionnels (même étrangers) d'occuper des espaces publics de qualité afin qu'ils partagent en toute honnêteté leur amour de l'art.
                        <br/><br/>
                        Enfin, nous tenons à remercier chaleureusement les services municipaux ainsi que Monsieur Boris Ravignon maire de Charleville Mézières et les édiles municipaux pour l'intérêt et la confiance qu'ils nous ont témoignés.
                        <br/><br/>
                        Jean-Paul BEUGNIES<br/>
                        Commissaire de l’exposition<br/>
                        Association VADO
                      </p>
                    </Col>
                </Row>
            </Grid>
        </div>
      </Layout>
    )
  }
}

export default SiteIndex

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
