import React from 'react'

import affiche from './../assets/images/Charleville/affiche.jpg'

const data = [
    {
        date: 'Octobre 2021',
        headline: 'Exposition de Charleville-Mézières - Affiche',
        image: affiche,
    }
];

export default data
