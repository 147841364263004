import React, {Component} from 'react'

import style from '../styles/header.module.css'
import DisplayImage from './../assets/images/main_image.jpg'

export class Header extends Component {
    render() {
        return (
            <div className={style.headerMain} style={{
                    backgroundImage: DisplayImage
                }}>
                <h2>Exposition de Charleville-Mézières</h2>
                <h3>Collectif d'artistes du Hainaut Belge</h3>
                <p>à la Galerie Lebon de Charleville-Mézières du 15 au 20 Octobre 2021</p>
            </div>
        )
    }
}

export default Header
